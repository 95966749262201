<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div id="main-container" class="">
    <div class="container">
      <div class="row">
        <div class=" products-container " v-bind:class="{ 'open-filter-container-mobile': filterContainerActive }">
          <div class="favorites-top-container">
            <div class="favorites-title"> {{ $t('favorites') }}</div>
            <div class="d-flex">
              <div class="me-4 favorite-nav-item"
                   @click="activeGrid = 'properties'"
                   :class="{ 'active-button': activeGrid === 'properties' }">
                {{ $t('favorite-properties') }}
              </div>
              <div class="favorite-nav-item"
                   @click="activeGrid = 'products'"
                   :class="{ 'active-button': activeGrid === 'products' }">
                {{ $t('favorite-products') }}
              </div>
              <div class="ms-auto d-none d-md-block" v-if="false">
                <MarketplaceSortFilter/>
              </div>
            </div>
          </div>
          <div v-if="activeGrid === 'products' && products">
            <div v-if="products && Object.keys(products).length > 0" class="row">
              <div class="col-md-2 col-6 favorite-product" v-for="product in products">
                <MarketplaceAd
                    :id="product.id"
                    :labelnew="product.labelnew"
                    :labeldiscount="product.labeldiscount"
                    :title="product.title"
                    :category="product.category"
                    :regularamount="product.regularamount"
                    :discountedamount="product.discountedamount"
                    :image="product.image"
                />
              </div>
            </div>
            <div class="alert alert-warning" role="alert" v-else>
              {{ $t('no-products-added-to-favorites-list') }}
            </div>
          </div>
          <div v-if="activeGrid === 'properties'">
            <div v-if="properties && Object.keys(properties).length > 0" class="row">
              <div class="col-md-3 col-12 favorite-product" v-for="property in properties">
                <PropertyAd
                    :property="property"
                    :id="property.id"
                    :adtype="property.adtype"
                    :city="property.city"
                    :district="property.district"
                    :title="property.title"
                    :amount="property.amount"
                    :images="property.images"
                />
              </div>
            </div>
            <div class="alert alert-warning" role="alert" v-else>
              {{ $t('no-properties-added-to-favorites-list') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

